<template>
  <nav class="directory__nav row gx-3 gy-3 mb-4">
    <div class="col-md-12 col-lg-4 col-xl-6">
      <div class="form-floating">
        <input
          class="form-control"
          type="text"
          placeholder="Search Directory"
          v-model="this.searchedFor"
          @input="emitSearchTerm"
        />
        <label for="directory-search"
          ><i class="bi bi-search"></i> Search by Keyword</label
        >
      </div>
    </div>
    <div class="col-md-6 col-lg-4 col-xl-3">
      <div class="form-floating">
        <select
          name="categories"
          id="directory-categories"
          class="form-select"
          v-model="this.categorySelected"
          @change="emitCategory"
        >
          <option selected value="">—All—</option>
          <option
            v-for="category in categoryList"
            :key="category"
            :value="category"
            >{{ upperFirst(category) }}</option
          >
        </select>
        <label for="directory-categories"
          ><i class="bi bi-funnel"></i> Filter by Category</label
        >
      </div>
    </div>
    <div class="col-md-6 col-lg-4 col-xl-3">
      <div class="row align-items-center">
        <div class="col-6 col-lg-6 col-md-6 ">
          <div class="form-floating">
            <input
              name="per-page"
              class="form-control"
              type="number"
              :min="this.minPerPage"
              :max="this.maxPerPage"
              step="5"
              placeholder="Per Page"
              v-model="this.perPageSelected"
            />
            <label for="per-page">Per Page</label>
          </div>
        </div>
        <div class="col-6 col-md-6 ">
          <input
            id="directory-per-page"
            type="range"
            class="form-range"
            v-model="this.perPageSelected"
            @change="emitPerPage"
            :min="this.minPerPage"
            :max="this.maxPerPage"
            step="5"
          />
        </div>
      </div>
    </div>
    <!-- <div class="col-12">
      <div class="directory__alpha">
        <a
          v-for="letter in alphabet"
          :key="letter"
          class="directory__alpha-link"
          :name="letter"
          :href="`#` + letter"
        >{{letter}}</a>
      </div>
    </div> -->
  </nav>
</template>

<script>
import _ from 'lodash';
export default {
  name: 'DirectoryNav',
  data() {
    return {
      alphabet: [
        'a',
        'b',
        'c',
        'd',
        'e',
        'f',
        'g',
        'h',
        'i',
        'j',
        'k',
        'l',
        'm',
        'n',
        'o',
        'p',
        'q',
        'r',
        's',
        't',
        'u',
        'v',
        'w',
        'x',
        'y',
        'z',
      ],
      searchedFor: null,
      categorySelected: '',
      minPerPage: 10,
      maxPerPage: 100,
      perPageSelected: null,
    };
  },
  props: {
    searchQuery: {
      type: String,
      default: '',
    },
    categoryList: {
      type: Array,
      default: null,
    },
    perPage: {
      type: Number,
      default: 25,
    },
  },
  components: {},
  mounted() {
    this.perPageSelected = this.perPage;
  },
  methods: {
    emitSearchTerm: _.debounce(function() {
      this.$emit('searchedFor', this.searchedFor);
    }, 200),
    emitCategory: _.debounce(function() {
      this.$emit('categorySelected', this.categorySelected);
    }, 0),
    emitPerPage: _.debounce(function() {
      this.$emit('perPageSelected', this.perPageSelected);
    }, 0),
    upperFirst(text) {
      return _.startCase(text);
    },
  },
  computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.directory {
  &__alpha {
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;
    justify-content: space-between;
    &-link {
      flex: 1;
      @include media-breakpoint-up(md) {
        flex-basis: 7%;
      }
      @include media-breakpoint-up(xxl) {
        flex: 1;
      }
      min-width: 2.5rem;
      text-align: center;
      line-height: 2.5;
      background: $beige;
      color: $black;
      text-transform: uppercase;
      font-family: $font-family-sans-serif;
      font-weight: $font-weight-bold;
      &:last-child {
        margin-right: 0;
      }
      &:hover,
      &:focus,
      &.active {
        background: $red;
        color: $white;
      }
    }
  }
}
</style>
