<template>
  <div class="directory">
    <DirectoryNav
      :searchQuery="searchQuery"
      :category="category"
      :categoryList="allCategories"
      @searchedFor="searchResults($event)"
      :perPage="perPage"
      @categorySelected="categoryFilterResults($event)"
      @perPageSelected="updatePagination($event)"
    ></DirectoryNav>
    <section v-if="errored">
      <p>Something went wrong. Please try again later.</p>
    </section>

    <section v-else>
      <div v-if="loading" data-aos="fade">
        Loading&hellip;
      </div>

      <DirectoryList
        v-else
        data-aos="fade-up"
        data-aos-offset="0"
        data-aos-delay="0"
        :items="directoryItems"
        :searchQuery="searchQuery"
        :category="category"
        :perPage="perPage"
      ></DirectoryList>
    </section>
  </div>
</template>

<script>
import xml2js from 'xml2js';
import _ from 'lodash';
import DirectoryNav from '@/components/DirectoryNav.vue';
import DirectoryList from '@/components/DirectoryList.vue';

export default {
  name: 'Directory',
  data() {
    return {
      directoryItems: [],
      loading: true,
      errored: false,
      searchQuery: '',
      category: '',
      perPage: 20,
    };
  },
  props: {
    dataURL: {
      type: String,
      required: true,
    },
  },
  components: {
    DirectoryNav,
    DirectoryList,
  },
  mounted() {
    if (sessionStorage.directoryItems) {
      try {
        this.directoryItems = JSON.parse(sessionStorage.directoryItems);
        this.errored = false;
        this.loading = false;
      } catch (e) {
        console.error(e);
        this.errored = true;
      }
    } else {
      this.getDirectoryItems();
    }
  },
  methods: {
    async getDirectoryItems() {
      console.log('fetching');
      this.axios
        .get(this.dataURL)
        .then((response) => {
          xml2js.parseString(response.data, (err, result) => {
            if (err) {
              // error handling
            } else {
              // console.log(result.entries.entry);
              let removeLocations = _.filter(result.entries.entry, item => !_.split(item.categories, ',').includes('locations') );
              // console.log(removeLocations);

              // this.directoryItems = result.entries.entry;
              this.directoryItems = removeLocations;
              let parsed = JSON.stringify(this.directoryItems);

              sessionStorage.setItem('directoryItems', parsed);
              // console.log(this.directoryItems);
            }
          });
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    searchResults(query) {
      this.searchQuery = query;
    },
    categoryFilterResults(query) {
      this.category = query;
    },
    updatePagination(query) {
      this.perPage = query;
    },
  },
  computed: {
    allCategories: function() {
      let categories = [];
      this.directoryItems.forEach((item) => {
        let itemCategories = _.split(item.categories, ',');
        itemCategories.forEach((category) => {
          if (category != '') categories.push(category);
        });
      });
      _.orderBy(categories, [(category) => category.value], ['asc']);
      return _.orderBy(_.uniq(categories));
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.directory {
}
</style>
