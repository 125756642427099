<template>
  <ul class="directory__list" ref="directory-list">
    <DirectoryItem
      :item="item"
      v-for="item in paginatedItems"
      :key="item.title"
    ></DirectoryItem>
  </ul>
  <nav aria-label="UGA Directory pages" v-if="this.pages.length > 1" class="mt-3">
    <ul class="directory__pagination pagination">
      <li
        class="page-item"
        :class="page == 1 ? 'disabled' : ''"
      >
        <button
          class="page-link"
          @click="
            page = 1;
            scrollTo('directory-list');
          "
          :aria-disabled="page == 1 ? true : false"
          :disabled="page == 1 ? '' : null"
          title="Go to First Page"
          aria-label="First Page"
        ><i aria-hidden="true" class="bi bi-chevron-double-left"></i></button>
      </li>
      <li
        class="page-item"
        :class="page == 1 ? 'disabled' : ''"
      >
        <button
          class="page-link"
          @click="
            page--;
            scrollTo('directory-list');
          "
          :aria-disabled="page == 1 ? true : false"
          :disabled="page == 1 ? '' : null"
          rel="prev"
          title="Go to Previous Page"
          aria-label="Previous Page"
        ><i aria-hidden="true" class="bi bi-chevron-left"></i></button>
      </li>
      <li
        class="page-item"
        v-for="pageNumber in pages.slice(page-1, page+5)"
        :key="pageNumber"
        :class="page == pageNumber ? 'active' : ''"
        :aria-current="page == pageNumber ? 'page' : null"
      >
        <button
          class="page-link"
          @click="
            page = pageNumber;
            scrollTo('directory-list');
          "
          :title="`Page ` + pageNumber"
          :aria-disabled="page == pageNumber ? true : null"
          :disabled="page == pageNumber ? '' : null"
        >{{pageNumber}}</button>
      </li>
      <li
        class="page-item"
        :class="page == pages.length ? 'disabled' : ''"
      >
        <button
          class="page-link"
          @click="
            page++;
            scrollTo('directory-list');
          "
          :aria-disabled="page == pages.length ? true : false"
          :disabled="page == pages.length ? '' : null"
          rel="next"
          title="Go to Next Page"
          aria-label="Next Page"
        ><i aria-hidden="true" class="bi bi-chevron-right"></i></button>
      </li>
      <li
        class="page-item"
        :class="page == pages.length ? 'disabled' : ''"
      >
        <button
          class="page-link"
          @click="
            page = pages.length;
            scrollTo('directory-list');
          "
          :aria-disabled="page == pages.length ? true : false"
          :disabled="page == pages.length ? '' : null"
          title="Go to Last Page"
          aria-label="Last Page"
        ><i aria-hidden="true" class="bi bi-chevron-double-right"></i></button>
      </li>
    </ul>
  </nav>
</template>

<script>
import _ from 'lodash';
import DirectoryItem from '@/components/DirectoryItem.vue';

export default {
  name: 'DirectoryList',
  data() {
    return {
      page: 1,
      pages: [],
    }
  },
  props: {
    items: {
      type: Object,
      required: true
    },
    searchQuery: {
      type: String,
      default: '',
    },
    category: {
      type: String,
      default: '',
    },
    perPage: {
      type: Number,
      default: 20,
    },
  },
  components: {
    DirectoryItem,
  },
  mounted() {
    this.setPages();
  },
  methods: {
    orderItems: function (items) {
      return _.orderBy(items, [item => _.lowerCase(item.title)], ['asc']);
    },
    filterItemsByTerm(items) {
      // Compare the lowercased query to the lowercase item.title. Return items that match.
      return _.filter(items, item => _.lowerCase( item.title ).includes( _.lowerCase( this.searchQuery ) ) );
    },
    filterItemsByCategory(items) {
      if (this.category != '') {
        // item.categories comes in as a String. Convert to an array and then check if the selected category is included in the array.
        return _.filter(items, item => _.split(item.categories, ',').includes(this.category) );
      } else {
        // if there is no category selected, return all items
        return items;
      }
    },
    setPages () {
      this.pages = [];
      let numberOfPages = Math.ceil(this.filteredItems.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate (posts) {
      let page = this.page;
      let perPage = this.perPage;
      let from = (page * perPage) - perPage;
      let to = (page * perPage);
      return  posts.slice(from, to);
    },
    scrollTo(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;
      
      window.scrollTo(0, top - this.getNavHeight());
    },
    getNavHeight() {
      let nav = document.querySelector('.navbar');
      return nav.offsetHeight;
    },
  },
  computed: {
    paginatedItems: function() {
      return this.paginate(
        this.filteredItems
      );
    },
    filteredItems: function() {
      return this.orderItems(
        this.filterItemsByTerm(
          this.filterItemsByCategory(
            this.items
          )
        )
      );
    },
  },
  watch: {
    paginatedItems () {
      this.setPages();
      // let numberOfPages = Math.ceil(this.filteredItems.length / this.perPage);
      if (this.page > this.pages.length ) {
        this.page = 1;
      }
    },
    filteredItems () {
      this.setPages();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .directory {
    &__list {
      margin: 0;
      padding: 0;
      list-style: none;
    }
    &__pagination {
    }
  }
</style>
