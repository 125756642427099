<template>
  <li class="directory__item" :data-category="this.item.categories">
    <a class="directory__item-link" :href="item.$.href">{{ item.title[0] }}</a>
    <div class="directory__item-social" v-if="item.social[0].$.state == 'true'">
      <a
        :href="social.link"
        class="directory__item-social-item"
        v-for="social in this.icons"
        :key="social.link"
        :aria-label="item.title[0] + ' on ' + social.platform"
        :title="item.title[0] + ' on ' + social.platform"
      >
        <font-awesome-icon
          class="social-icon"
          :icon="[social.prefix, social.icon]"
          fixed-width
          aria-hidden="true"
        />
      </a>
    </div>
  </li>
</template>

<script>
import _ from 'lodash';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faSnapchatGhost,
  faYoutube,
  faLinkedinIn,
  faFlickr,
} from '@fortawesome/free-brands-svg-icons';
import { faRss } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(
  faFacebookF,
  faTwitter,
  faInstagram,
  faSnapchatGhost,
  faYoutube,
  faLinkedinIn,
  faFlickr,
  faRss
);

export default {
  name: 'DirectoryItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      icons: [],
    };
  },
  components: {
    FontAwesomeIcon,
  },
  mounted() {
    this.parseSocials(this.item.social[0]);
  },
  methods: {
    parseSocials(item) {
      if (item.$.state == 'true') {
        if ('facebook' in item) {
          this.icons.push({
            prefix: 'fab',
            icon: 'facebook-f',
            platform: 'Facebook',
            link: item.facebook[0].$.href,
          });
        }
        if ('instagram' in item) {
          this.icons.push({
            prefix: 'fab',
            icon: 'instagram',
            platform: 'Instagram',
            link: item.instagram[0].$.href,
          });
        }
        if ('twitter' in item) {
          this.icons.push({
            prefix: 'fab',
            icon: 'twitter',
            platform: 'Twitter',
            link: item.twitter[0].$.href,
          });
        }
        if ('linkedin' in item) {
          this.icons.push({
            prefix: 'fab',
            icon: 'linkedin-in',
            platform: 'LinkedIn',
            link: item.linkedin[0].$.href,
          });
        }
        if ('rss' in item) {
          this.icons.push({
            prefix: 'fa',
            icon: 'rss',
            platform: 'Blog',
            link: item.rss[0].$.href,
          });
        }
        if ('youtube' in item) {
          this.icons.push({
            prefix: 'fab',
            icon: 'youtube',
            platform: 'YouTube',
            link: item.youtube[0].$.href,
          });
        }
        if ('flickr' in item) {
          this.icons.push({
            prefix: 'fab',
            icon: 'flickr',
            platform: 'Flickr',
            link: item.flickr[0].$.href,
          });
        }
        if ('snapchat' in item) {
          this.icons.push({
            prefix: 'fab',
            icon: 'snapchat-ghost',
            platform: 'Snapchat',
            link: item.snapchat[0].$.href,
          });
        }
      }
    },
  },
  computed: {
    categories: function() {
      return _.join(this.item.categories, ',');
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.directory {
  &__item {
    border-bottom: solid 1px $gray-300;
    font-family: $font-family-sans-serif;
    font-weight: $font-weight-bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:nth-child(even) {
      background-color: fade-out($black, 0.99);
    }
    &-social {
      display: flex;
      &-item {
        padding: 1rem 0.5rem;
        &:hover,
        &:focus {
          background-color: fade-out($black, 0.95);
        }
      }
    }
    &-link {
      display: block;
      color: $black;
      padding: 1rem;
      width: 100%;
      &:hover,
      &:focus {
        color: $red;
        background-color: fade-out($black, 0.95);
      }
    }
  }
}
.social-icon {
  font-size: 1.3rem;
}
</style>
